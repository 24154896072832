import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const Boleto = () => {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Boleto
      </Typography>
      <TextField
        label="Número do Boleto"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Nome do Beneficiário"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Valor"
        fullWidth
        margin="normal"
        required
      />
    </Box>
  );
};

export default Boleto;
