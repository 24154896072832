import React, { useState, useEffect, useContext } from 'react';
import { Alert, Grid, Paper, Snackbar, TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { SharedContext } from './../context';
import { motion } from "framer-motion";
import { crypt } from './../utils/crypt'
import axios from 'axios';

// Função para validar CPF
const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11) return false;
  let sum = 0;
  let remainder;
  if (/^(\d)\1{10}$/.test(cpf)) return false;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  return remainder === parseInt(cpf.substring(10, 11));
};

// Função para validar a data e calcular a idade
const isValidDate = (date) => {
  const [day, month, year] = date.split('/').map(Number);
  const dateObj = new Date(year, month - 1, day);
  const age = new Date().getFullYear() - year;
  return (
    dateObj.getFullYear() === year &&
    dateObj.getMonth() + 1 === month &&
    dateObj.getDate() === day &&
    age >= 18 &&
    age <= 100
  );
};

// Schema de validação usando yup
const validationSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('O CPF é obrigatório.')
    .test('is-valid-cpf', 'CPF inválido.', (value) => {
      const cleanedValue = value.replace(/[^\d]+/g, '');
      return cleanedValue.length === 11 ? isValidCPF(value) : true;
    }),
  dob: yup
    .string()
    .required('A data de nascimento é obrigatória.')
    .test('is-valid-date', 'Data de nascimento inválida.', (value) => {
      const cleanedValue = value.replace(/[^\d]+/g, '');
      return cleanedValue.length === 8 ? isValidDate(value) : true;
    }),
});

const Login = () => {

  const [cpf, setCpf] = useState('');
  const [dob, setDob] = useState('');
  const [company, setCompany] = useState(null);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({ cpf: false, dob: false });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setSharedState } = useContext(SharedContext);
  const { companyName } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [companyFound, setCompanyFound] = useState(null);

  if (companyName !== 'error') {
    localStorage.setItem("companyName", companyName);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_API_URL+'/loginParams/'+companyName);
        setCompany(data);
        localStorage.setItem("company", JSON.stringify(data));
        document.title = data.name;
        setCompanyFound(true)
      } catch (error) {
        setErrorMessage(error.response ? error.response.data.message : 'Erro. Verifique e tente novamente');
        setOpenSnackbar(true)
        setCompanyFound(false)
      }
    };
    fetchData();
  }, [])

  useEffect(() => {
    const validateFields = async () => {
      try {
        await validationSchema.validate({ cpf, dob }, { abortEarly: false });
        setErrors({});
        setIsButtonDisabled(false);
      } catch (err) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        setIsButtonDisabled(true);
      }
    };

    validateFields();
  }, [cpf, dob]);

  const validateField = async (field, value) => {
    try {
      await validationSchema.validate({ [field]: value }, { abortEarly: false });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors((prev) => ({ ...prev, ...validationErrors }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched({ cpf: true, dob: true });

    try {
      await validationSchema.validate({ cpf, dob }, { abortEarly: false });
      setIsLoading(true);
      try {
        const birthDateSplited = dob.split('/')
        const birthDate = [birthDateSplited[2],birthDateSplited[1],birthDateSplited[0],].join('-')
        const { data } = await axios.post(process.env.REACT_APP_API_URL+'/invoice/'+companyName, {
          c1: crypt(cpf),
          b1: crypt(birthDate),
        });
        setSharedState(data);
        localStorage.setItem('data', JSON.stringify(data));
        navigate(`/${companyName}/invoices`);
      } catch (error) {
        setErrorMessage(error.response ? error.response.data.message : 'Erro. Verifique e tente novamente');
        setOpenSnackbar(true)
      }
      setIsLoading(false);
    } catch (err) {
    }
  };

  const handleInputCpfChange = (e) => {
    const { value } = e.target;
    if (value.length <= 14) {
      // Aplica a máscara CPF
      const maskedValue = value
        .replace(/\D/g, '') // Remove tudo que não for número
        .replace(/(\d{3})(\d)/, '$1.$2') // Insere o primeiro ponto
        .replace(/(\d{3})(\d)/, '$1.$2') // Insere o segundo ponto
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Insere o hífen
  
      setCpf(maskedValue)
    }
  };

  const handleInputDobChange = (e) => {
    const { value } = e.target;
    // Limita o input para no máximo 10 caracteres (DD/MM/YYYY)
    if (value.length <= 10) {
      // Aplica a máscara de Data de Nascimento
      const maskedValue = value
        .replace(/\D/g, '') // Remove tudo que não for número
        .replace(/(\d{2})(\d)/, '$1/$2') // Insere a primeira barra
        .replace(/(\d{2})(\d)/, '$1/$2') // Insere a segunda barra
        .replace(/(\d{4})$/, '$1'); // Mantém o ano com 4 dígitos
      setDob(maskedValue);
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  }

  return (
    <>
      { companyFound == null ? (
        <>
          <div>Carregando...</div>
        </>
      ) : companyFound == false ? (
        <>
          <div>Empresa não encontrada. Verifique o link e tente novamente.</div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          sx={{
            // display: 'flex',
            // justifyContent: 'center',  // Centraliza horizontalmente
            // alignItems: 'center',      // Centraliza verticalmente
            // height: '100vh',           // Ocupa a altura total da viewport
          }}
          
        >
        <Grid
          container
          component="main"
          sx={{
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              margin: 4,
              padding: 4,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '16px',
              width: '300px',
              // justifyContent: 'center',
            }}
          >
            <img
              src={company.logoUrl}  // Coloque aqui a URL da imagem
              alt="Login"
              style={{ 
                // margin: '20px auto', 
                width: '100px', 
                height: '100px', 
              }}
            />
            <Typography variant="h5" component="h1" gutterBottom>
              {company.name}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="CPF"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                inputProps={{
                  inputMode: "numeric", // Abre teclado numérico em dispositivos móveis
                }}
                error={(touched.cpf || cpf.length === 14) && !!errors.cpf}
                helperText={(touched.cpf || cpf.length === 14) && errors.cpf}
                onChange={handleInputCpfChange}
                value={cpf}
                disabled={isLoading}
              />
              
              <TextField
                label="Data de Nascimento"
                variant="outlined"
                fullWidth
                type="text"
                inputProps={{
                  onInput: handleInputDobChange,
                  inputMode: "numeric", // Abre teclado numérico em dispositivos móveis
                }}
                margin="normal"
                error={(touched.dob || dob.length === 10) && !!errors.dob}
                helperText={(touched.dob || dob.length === 10) && errors.dob}
                onChange={handleInputDobChange}
                disabled={isLoading}
                value={dob}
              />
              {errors.form && (
                <Typography color="error" align="center">
                  {errors.form}
                </Typography>
              )}
              <Box mt={2} display="flex" justifyContent="center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={isButtonDisabled}
                  >
                    Entrar
                  </Button>
                )}
              </Box>
            </form>
            <img
              src={company.logo}
              style={{ 
                margin: '20px auto', 
                width: '50%', 
                opacity: '0'
              }}
            />
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
                {errorMessage}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </motion.div>
      )}
    </>
  );
};

export default Login;
