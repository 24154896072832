import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper, Snackbar, Alert, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import { SharedContext } from './../context';
import { motion } from "framer-motion";
import { crypt } from './../utils/crypt'
import { formatCurrency } from './../utils/validators'
import axios from 'axios';

// Funções utilitárias para formatação
const formatDate = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
  return new Date(dateString).toLocaleDateString('pt-BR', options);
};

const Invoices = () => {
  const [selected, setSelected] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Hook do React Router para navegação
  const { sharedState, setSharedState } = useContext(SharedContext); // Utilize o contexto
  const { companyName } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const allSelected = sharedState.invoices.length > 0 && 
                        sharedState.invoices.every((_, index) => selected[index]);
    setSelectAll(allSelected);
  }, [selected, sharedState.invoices]);

  const handleCheckboxChange = (index) => {
    const invoice = sharedState.invoices[index];
    const newSelection = { ...selected };

    if (!newSelection[index]) {
      newSelection[index] = true;

      sharedState.invoices.forEach((inv, i) => {
        if (inv.code === invoice.code && inv.installmentCode < invoice.installmentCode) {
          newSelection[i] = true;
        }
      });
    } else {
      const hasLarger = sharedState.invoices
        .filter(inv => inv.code === invoice.code && inv.installmentCode > invoice.installmentCode)
        .some(inv => newSelection[sharedState.invoices.findIndex(invItem => invItem.installmentCode === inv.installmentCode && invItem.code === inv.code)]);

      if (hasLarger) {
        const largerInvoice = sharedState.invoices.find(inv => inv.code === invoice.code && inv.installmentCode > invoice.installmentCode && newSelection[sharedState.invoices.indexOf(inv)]);
        setMessage(`Para pagar a parcela ${largerInvoice.installmentCode} você deve pagar a ${invoice.installmentCode}`);
        setSnackbarOpen(true);
        return;
      }

      newSelection[index] = false;
    }

    setSelected(newSelection);
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelected(sharedState.invoices.reduce((acc, _, index) => {
      acc[index] = newSelectAll;
      return acc;
    }, {}));
  };

  const handleRowClick = (index) => {
    handleCheckboxChange(index);
  };

  const totalSelected = Object.values(selected).filter(v => v).length;
  const totalValue = sharedState.invoices
    .filter((_, index) => selected[index])
    .reduce((sum, invoice) => sum + invoice.value, 0);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getCheckboxColor = (index) => {
    const invoice = sharedState.invoices[index];
    const hasLarger = sharedState.invoices
      .filter(inv => inv.code === invoice.code && inv.installmentCode > invoice.installmentCode)
      .some(inv => selected[sharedState.invoices.findIndex(invItem => invItem.installmentCode === inv.installmentCode && invItem.code === inv.code)]);

    if (selected[index] && !hasLarger) {
      return 'primary'; // Azul
    } else if (selected[index] && hasLarger) {
      return 'default'; // Cinza
    } else {
      return 'default';
    }
  };

  const handlePayClick = async () => {
    // Armazenar a seleção no localStorage
    const selectedInvoices = sharedState.invoices.filter((_, index) => selected[index]);
    // localStorage.setItem('selected', JSON.stringify(selectedInvoices));

    setIsLoading(true);
    try {
      await axios.put(process.env.REACT_APP_API_URL+'/l/'+companyName, {
        u: crypt(sharedState.id),
        f: crypt('selected'),
        v: crypt(JSON.stringify(selectedInvoices)),
      });
      setSharedState(sharedState => ({
        ...sharedState,
        selected: selectedInvoices
      }))
      navigate(`/${companyName}/payment`);
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Erro. Verifique e tente novamente');
      setSnackbarOpen(true)
    }
    setIsLoading(false);
  };

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
      <Container 
        maxWidth="md"
      >
        <Header/>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={4}
        >
          { sharedState.invoices.length == 0 ? (
            <>
              Você não tem parcelas vencidas e/ou a vencer.
            </>
          ) : (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                {sharedState.customer.name}
              </Typography>
              <TableContainer component={Paper} style={{ maxWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="invoices-table">
                        Todas<br/>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                      <TableCell className="invoices-table">Fatura</TableCell>
                      <TableCell className="invoices-table">Parcela</TableCell>
                      <TableCell className="invoices-table">Vencimento</TableCell>
                      <TableCell className="invoices-table">Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sharedState.invoices.map((invoice, index) => (
                      <TableRow
                        key={index}
                        hover
                        onClick={() => handleRowClick(index)}
                        style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}
                      >
                        <TableCell className="invoices-table">
                          <Checkbox
                            checked={!!selected[index]}
                            onChange={() => handleCheckboxChange(index)}
                            color={getCheckboxColor(index)}
                          />
                        </TableCell>
                        <TableCell className="invoices-table">
                          {invoice.companyId}.{invoice.code}
                        </TableCell>
                        <TableCell className="invoices-table">
                          {invoice.installmentCode}
                        </TableCell>
                        <TableCell className="invoices-table">{formatDate(invoice.expiredDate)}</TableCell>
                        <TableCell className="invoices-table">{formatCurrency(invoice.value)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className="invoices-table">
                        Total {totalSelected}
                      </TableCell>
                      <TableCell className="invoices-table"></TableCell>
                      <TableCell className="invoices-table"></TableCell>
                      <TableCell className="invoices-table"></TableCell>
                      <TableCell className="invoices-table">{formatCurrency(totalValue)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                color="primary"
                disabled={totalSelected < 1}
                onClick={handlePayClick}
                style={{ marginTop: '16px' }}
              >
                Pagar
              </Button>
            </>
          )}
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="info">
              {message}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </motion.div>
  );
};

export default Invoices;
