import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Login from './components/Login';
import Invoices from './components/Invoices';
import Payment from './components/Payment';
import { SharedProvider } from './context';
import ProtectedRoute from './ProtectedRoute'; // Importe o componente

const RedirectToLogin = () => {
  let { companyName } = useParams(); // Captura o prefixo da URL
  if (companyName === undefined) {
    if (localStorage.getItem("companyName") && localStorage.getItem("companyName") !== '') {
      companyName = localStorage.getItem("companyName")
    } else {
      companyName = 'error'
    }
  }
  return <Navigate to={`/${companyName}/login`} />;
};

const App = () => {
  return (
    <SharedProvider>
      <Router>
        <Routes>
          <Route path="/:companyName/login" element={<Login />} />
          <Route
            path="/:companyName/invoices"
            element={
              <ProtectedRoute>
                <Invoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:companyName/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          {/* Redireciona qualquer rota com o prefixo para login */}
          <Route path="/:companyName" element={<RedirectToLogin />} />
          <Route path="/" element={<RedirectToLogin />} />
        </Routes>
      </Router>
    </SharedProvider>
  );
};

export default App;
