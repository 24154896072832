import React from 'react';
import { Typography, AppBar, Toolbar, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate(); // Hook do React Router para navegação
  const handleLogout = () => {
    localStorage.removeItem('data');
    localStorage.removeItem('company');
    navigate('/'); // Redireciona para a página inicial
  };

  return (
    <AppBar position="static" sx={{ 
      color: '#8c8c8c',
      backgroundColor: '#f5f5f5',
    }}>
      <Toolbar sx={{ height: 50 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {JSON.parse(localStorage.getItem('company')).name}
        </Typography>
        <Link 
          color="inherit" 
          onClick={handleLogout} 
          style={{ cursor: 'pointer' }}
        >
          Sair
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
