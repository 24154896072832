export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '') // Remove qualquer coisa que não é dígito
    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona um ponto entre o terceiro e o quarto dígito
    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona um ponto entre o sexto e o sétimo dígito
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2') // Adiciona um hífen antes dos últimos 2 dígitos
    .slice(0, 14); // Limita o tamanho máximo
};

export const dateMask = (value) => {
  return value
    .replace(/\D/g, '') // Remove qualquer coisa que não é dígito
    .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona uma barra entre o segundo e o terceiro dígito
    .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona uma barra entre o quarto e o quinto dígito
    .slice(0, 10); // Limita o tamanho máximo
};

export const formatCurrency = (amount) => {
  return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const formatNumber = (amount) => {
  return amount.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2 });
};

export const isValidCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

  if (cpf.length !== 11) return false;

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cpf)) return false;

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const isValidDate = (date) => {
  // Regex para validar o formato DD/MM/YYYY
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;

  if (!dateRegex.test(date)) return false;

  const [day, month, year] = date.split('/').map(Number);

  const isValid = (day, month, year) => {
    const dateObj = new Date(year, month - 1, day);
    return (
      dateObj.getFullYear() === year &&
      dateObj.getMonth() === month - 1 &&
      dateObj.getDate() === day
    );
  };

  return isValid(day, month, year);
};


export const isValidCardNumber = (cardNumber) => {
  // Remove espaços em branco
  cardNumber = cardNumber.replace(/\s+/g, '');
  
  // Algoritmo de Luhn
  let sum = 0;
  let shouldDouble = false;
  
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber[i]);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  
  return sum % 10 === 0;
}

export const isValidExpiryDate = (cardExpiry) => {
  // Verifica se está no formato MM/YY
  if (!/^\d{2}\/\d{2}$/.test(cardExpiry)) return false;
  
  const [month, year] = cardExpiry.split('/').map(Number);
  const currentYear = new Date().getFullYear() % 100;
  const currentMonth = new Date().getMonth() + 1;
  
  // Verifica se o mês é válido
  if (month < 1 || month > 12) return false;
  
  // Verifica se a data de expiração não está no passado
  if (year < currentYear || (year === currentYear && month < currentMonth)) return false;
  
  return true;
}

export const isValidCVV = (cardCVV) => {
  // Verifica se o CVV tem 3 ou 4 dígitos
  return /^\d{3,4}$/.test(cardCVV);
}

export const isValidInstallmentNumber = (installmentNumber) => {
  // Verifica se o número de parcelas é um valor razoável (1-36, por exemplo)
  return Number.isInteger(installmentNumber) && installmentNumber >= 1 && installmentNumber <= 36;
}

export const validatePaymentDetails = (payment) => {
  const { creditCard } = payment;
  const { cardNumber, cardName, cardExpiry, cardCVV, installmentNumber } = creditCard;

  if (!isValidCardNumber(cardNumber)) {
    return { isValid: false, message: "Número do cartão de crédito inválido." };
  }

  if (!cardName || typeof cardName !== 'string') {
    return { isValid: false, message: "Nome no cartão inválido." };
  }

  if (!isValidExpiryDate(cardExpiry)) {
    return { isValid: false, message: "Data de expiração inválida." };
  }

  if (!isValidCVV(cardCVV)) {
      return { isValid: false, message: "Código CVV inválido." };
  }

  if (!isValidInstallmentNumber(installmentNumber)) {
    return { isValid: false, message: "Número de parcelas inválido." };
  }

  return { isValid: true, message: "Todos os dados do cartão são válidos." };
}
