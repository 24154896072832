import React, { useEffect, useState, useContext } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { SharedContext } from './../../context';

const DebitCard = () => {
  const { sharedState, setSharedState } = useContext(SharedContext);

  useEffect(() => {
    // Inicializa o creditCard no sharedState
    setSharedState(prevState => ({
      ...prevState,
      payment: {
        debitCard: {}
      }
    }));

  }, [sharedState.selected]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Cartão de Débito
      </Typography>
      <TextField
        label="Número do Cartão"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Nome no Cartão"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Data de Validade"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="CVV"
        fullWidth
        margin="normal"
        required
      />
      <Typography variant="body2" sx={{ color: '#888', marginTop: 2 }}>
        Cartões de débito aceitos:
      </Typography>
      <Typography variant="body2" sx={{ color: '#888', marginTop: 2 }}>
        Ultilizamos o protocolo de autenticação 3D Secure (3DS) 2.0 para garantir a segurança das transações de cartões de crédito e débito.
      </Typography>
      <Typography variant="body2" sx={{ color: '#888', marginTop: 2 }}>
        Os valores das parcelas poderão sofrer uma variação em razão da forma, prazo e meio de pagamento, podendo ser acrescidos custos financeiros e/ou outras taxas.
      </Typography>
      <Typography variant="body2" sx={{ color: '#888', marginTop: 2 }}>
        Os pagamentos em Pix, Cartão de débito, Cartão de Crédito ou QR Code™ Pay podem ser confirmados em até 24 horas. Porem normalmente são confirmados em minutos.
      </Typography>
    </Box>
  );
};

export default DebitCard;
