import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SharedContext } from './context';

const ProtectedRoute = ({ children }) => {
  const { sharedState } = useContext(SharedContext);
  if (!sharedState.customer || !sharedState.customer.cpf) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
