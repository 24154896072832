import React, { useEffect, useState, useContext } from 'react';
import { Box, Chip, Grid, TextField, Typography, FormControlLabel, RadioGroup, Radio, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { formatNumber } from './../../utils/validators'
import { SharedContext } from './../../context';

// Esquema de validação usando Yup
const validationSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .required('Número do cartão é obrigatório')
    .matches(/^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/, 'Número do cartão inválido'),
  cardName: yup
    .string()
    .required('Nome no cartão é obrigatório'),
  cardExpiry: yup
    .string()
    .required('Data de validade é obrigatória')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4})$/, 'Data de validade inválida')
    .test('valid-expiry-date', 'Data de validade expirada', function (value) {
      if (!value) return false;
      const [month, year] = value.split('/');
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const expiryYear = parseInt(year, 10);
      const expiryMonth = parseInt(month, 10);
      if (expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)) {
        return true;
      }
      return false;
    }),
  cardCVV: yup
    .string()
    .required('CVV é obrigatório')
    .matches(/^\d{3,4}$/, 'CVV inválido') // Permite 3 ou 4 dígitos
});

const interestRates = {
  2: 2.920,
  3: 3.265,
  4: 3.461,
  5: 3.583,
  6: 3.985,
  7: 4.041,
  8: 4.077,
  9: 4.101,
  10: 4.116,
  11: 4.285,
  12: 4.287
};

const calculateInstallment = (totalAmount, installments) => {
  const rate = (interestRates[installments] / 100) || 0;
  if (rate === 0) {
    return formatNumber(totalAmount / installments);
  } else {
    const installmentValue = (totalAmount * rate) / (1 - Math.pow(1 + rate, -installments));
    return formatNumber(installmentValue);
  }
};

const CreditCard = () => {
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [showAllInstallments, setShowAllInstallments] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const { sharedState, setSharedState } = useContext(SharedContext);

  const { control, handleSubmit, watch, setValue, formState: { errors, isSubmitted } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const cardNumber = watch('cardNumber');
  const cardName = watch('cardName');
  const cardExpiry = watch('cardExpiry');
  const cardCVV = watch('cardCVV');

  useEffect(() => {
    // Inicializa o creditCard no sharedState
    setSharedState(prevState => ({
      ...prevState,
      payment: {
        ...prevState.payment,
        creditCard: {
          cardNumber: '',
          cardName: '',
          cardExpiry: '',
          cardCVV: '',
          installmentNumber: null
        }
      }
    }));

    const total = sharedState.selected?.reduce((acc, item) => acc + (item.value || 0), 0) || 0;
    setTotalAmount(total);
  }, [sharedState.selected]);

  useEffect(() => {
    if (cardNumber || cardName || cardExpiry || cardCVV || selectedInstallment) {
      setSharedState(prevState => ({
        ...prevState,
        payment: {
          ...prevState.payment,
          creditCard: {
            cardNumber: cardNumber || prevState.payment.creditCard.cardNumber,
            cardName: cardName || prevState.payment.creditCard.cardName,
            cardExpiry: cardExpiry || prevState.payment.creditCard.cardExpiry,
            cardCVV: cardCVV || prevState.payment.creditCard.cardCVV,
            installmentNumber: selectedInstallment || prevState.payment.creditCard.installmentNumber
          }
        }
      }));
    }
  }, [cardNumber, cardName, cardExpiry, cardCVV, selectedInstallment]);

  const handleInstallmentChange = (installmentNumber) => {
    setSelectedInstallment(installmentNumber);
    setShowAllInstallments(false); // Ocultar todas as parcelas ao selecionar uma
  };

  const toggleInstallments = () => {
    setShowAllInstallments(prev => !prev);
  };

  const onSubmit = (data) => {
    console.log('Dados do cartão:', data);
    // Lógica adicional para submissão do formulário
  };

  return (
    <Box sx={{ marginTop: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
    <Typography variant="h6" gutterBottom>
      Escolha a parcela
    </Typography>
    
    <RadioGroup value={selectedInstallment}>
      {Array.from({ length: 12 }, (_, i) => {
        const installmentNumber = 12 - i;
        const amount = calculateInstallment(totalAmount, installmentNumber);
        const isHighlighted = installmentNumber === selectedInstallment;
        const isRecommended = installmentNumber === 6;
        const isLowest = installmentNumber === 12;

        if (!showAllInstallments && !isHighlighted && !isLowest && !isRecommended && installmentNumber !== selectedInstallment) {
          return null; // Oculta parcelas não destacadas se `showAllInstallments` for `false`
        }

        return (
          <Box 
            key={installmentNumber} 
            onClick={() => handleInstallmentChange(installmentNumber)}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              border: `1px solid ${isHighlighted ? '#4CAF50' : '#333'}`,
              borderRadius: '8px',
              padding: '8px',
              marginBottom: '8px',
              backgroundColor: isHighlighted ? '#4CAF5010' : 'transparent',
              cursor: 'pointer'
            }}
          >
            <FormControlLabel 
              value={installmentNumber}
              control={<Radio />}
              label={`${installmentNumber}x de R$ ${amount}`}
              sx={{ flex: 1, margin: 0 }}
            />
            {isLowest && <Chip label="Menor parcela" size="small" variant="outlined" />}
            {installmentNumber === 6 && <Chip label="Recomendado" size="small" variant="outlined" />}
          </Box>
        );
      })}
    </RadioGroup>

    {!showAllInstallments && (
      <Button onClick={toggleInstallments}>
        Exibir todas
      </Button>
    )}

    {selectedInstallment && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cardNumber"
              control={control}
              render={({ field }) => (
                <InputMask mask="9999 9999 9999 9999" {...field}>
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="Número do Cartão"
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.cardNumber}
                      helperText={errors.cardNumber?.message}
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cardName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome no Cartão"
                  fullWidth
                  margin="normal"
                  required
                  error={!!errors.cardName}
                  helperText={errors.cardName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cardExpiry"
              control={control}
              render={({ field }) => (
                <InputMask mask="99/9999" {...field}>
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="Data de Validade"
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.cardExpiry}
                      helperText={errors.cardExpiry?.message}
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="cardCVV"
              control={control}
              render={({ field }) => (
                <InputMask mask="9999" maskChar=""  {...field}>
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="CVV"
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.cardCVV}
                      helperText={errors.cardCVV?.message}
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
        </Grid>
      </>
    )}
  </Box>
  );
};

export default CreditCard;
