import React, { useState, useEffect, useContext } from 'react';
import { Alert, CircularProgress, Container, Box, Grid, Typography, TextField, Paper, RadioGroup, FormControlLabel, Radio, Button, Snackbar } from '@mui/material';
import InputMask from 'react-input-mask'; // Importa a biblioteca para máscara
import Header from './Header';
import CreditCard from './PaymentMethod/CreditCard';
import DebitCard from './PaymentMethod/DebitCard';
import Pix from './PaymentMethod/Pix';
import Boleto from './PaymentMethod/Boleto';
import { SharedContext } from './../context';
import { validatePaymentDetails } from './../utils/validators'
import { crypt } from './../utils/crypt'
import { formatCurrency } from './../utils/validators'
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Ícone para o botão
import axios from 'axios';

const Payment = () => {
  const { companyName } = useParams();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const { sharedState, setSharedState } = useContext(SharedContext); // Utilize o contexto
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [paymentResData, setPaymentResData] = useState(null)
  const [copySuccess, setCopySuccess] = useState('');
  
  useEffect(() => {
    sharedState.payment = {
      method: null,
    }
    // sharedState.customer.email = 'asdfasdf@asdfasdf.com'
    // sharedState.customer.phone = '99999999999'
    setSharedState(sharedState);
  }, []);

  useEffect(() => {
    // Calcula a soma dos valores no atributo 'selected'
    const total = sharedState.selected?.reduce((acc, item) => acc + (item.value || 0), 0) || 0;
    setTotalValue(total);
    checkPayment()
    // console.log(sharedState)
  }, [sharedState]);
  
  const checkPayment = () => {
    if (
      sharedState.customer.phone !== ''
      && sharedState.customer.email !== ''
      && sharedState.payment.method !== undefined
    ) {
      if (sharedState.payment.method === 'creditCard') {
        try {
          validatePaymentDetails(sharedState.payment)
          setIsPaymentEnabled(true)
        } catch (error) {
          console.error(error)
          setIsPaymentEnabled(false)
        }
      } else if (sharedState.payment.method === 'pix') {
        setIsPaymentEnabled(true)
      }
    } else {
      setIsPaymentEnabled(false);
    }
  }

  const handlePaymentMethodChange = (event) => {
    console.log('handlePaymentMethodChange', event.target.value)
    let payment = sharedState.payment
    payment.method = event.target.value
    delete payment.boleto
    delete payment.debitCard
    delete payment.creditCard
    delete payment.pix
    setSharedState({ ...sharedState, payment });
    setPaymentMethod(event.target.value);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(paymentResData.pix.copyAndPast)
      .then(() => {
        setCopySuccess('Copiado!');
        setTimeout(() => setCopySuccess(''), 2000); // Limpa a mensagem após 2 segundos
      })
      .catch((err) => {
        setCopySuccess('Falha ao copiar');
      });
  };

  const logIt = async (f, v) => {
    // try {
      await axios.put(process.env.REACT_APP_API_URL+'/l/'+companyName, {
        u: crypt(sharedState.id),
        f: crypt(f),
        v: crypt(v),
      });
      // setSharedState(sharedState => ({
      //   ...sharedState,
      //   selected: selectedInvoices
      // }))
      // navigate(`/${companyName}/payment`);
    // } catch (error) {
    //   setMessage(error.response ? error.response.data.message : 'Erro. Verifique e tente novamente');
    //   setSnackbarOpen(true)
    // }
  }

  const handlePhoneChange = (event) => {
    const phone = event.target.value;
    setSharedState({ ...sharedState, phone });
    const valid = phone.match(/\(\d{2}\) \d{5}-\d{4}/) !== null
    if (valid) {
      logIt('phone', phone)
    }
    setIsPhoneValid(valid);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setSharedState({ ...sharedState, email });
    const valid = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
    if (valid) {
      logIt('email', email)
    }
    setIsEmailValid(valid);
  };

  const handleConfirmPayment = async () => {
    // if (sharedState.payment?.creditCard?.cardNumber) {
    //   const encrypted = CryptoJS.AES.encrypt(sharedState.payment?.creditCard?.cardNumber, secretKey).toString();
    //   console.log('Número do cartão criptografado:', encrypted);
    // }
    setIsLoading(true);
    try {
      await axios.put(process.env.REACT_APP_API_URL+'/l/'+companyName, {
        u: crypt(sharedState.id),
        f: crypt('payment'),
        v: crypt(JSON.stringify(sharedState.payment)),
      });
      const payment = await axios.post(process.env.REACT_APP_API_URL+'/payment/'+companyName, {
        u: crypt(sharedState.id),
      });

      // alert(payment.data.status)

      setPaymentResData(payment.data)
      
    } catch (error) {
      setErrorMessage(error.response ? error.response.data.message : 'Erro. Verifique e tente novamente');
      setOpenSnackbar(true)
    }
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  }

  return (paymentResData && paymentResData.pix) ? (
    <Container maxWidth="md">
      <Header/>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={4}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="h6" gutterBottom>
              Dados para seu pagamento:
            </Typography>
            Chave PIX Copia e cola: 
            <Box
              sx={{
                backgroundColor: '#f5f5f5', // Cor de fundo para destacar o código
                padding: '10px',
                borderRadius: '8px',
                fontFamily: 'monospace', // Fonte de monoespaço
                overflowX: 'auto', // Scroll horizontal para longas linhas de código
              }}
            >
              <code>{paymentResData.pix.copyAndPast}</code>
            </Box>
            <Box
              sx={{
                marginBottom: '20px',
              }}
            >
              <Button
                onClick={handleCopyToClipboard}
                variant="contained"
                startIcon={<ContentCopyIcon />}
                // sx={{ position: 'absolute', top: '8px', right: '8px' }}
              >
                Copiar
              </Button>
              {copySuccess && (
                <Typography
                  variant="body2"
                  sx={{ mt: 1, color: 'green', fontStyle: 'italic' }}
                >
                  {copySuccess}
                </Typography>
              )}
            </Box>
            
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center', // Centraliza horizontalmente
                alignItems: 'center',     // Centraliza verticalmente
              }}
            >
              <img src={paymentResData.pix.qrCodeImg} style={{ height: 300 }} />
            </Box>
          </Paper>
        </motion.div>
      </Box>
    </Container>
  ) : (
    <Container maxWidth="md">
      <Header/>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={4}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="h6" gutterBottom>
              Dados para pagamento
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nome"
                  value={sharedState.customer.name}
                  fullWidth
                  margin="normal"
                  InputProps={{ readOnly: true, disabled: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CPF"
                  value={sharedState.customer.cpf}
                  fullWidth
                  margin="normal"
                  InputProps={{ readOnly: true, disabled: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(99) 99999-9999"
                  value={sharedState.customer.phone}
                  onChange={handlePhoneChange}
                >
                  {() => <TextField
                    label="Celular"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric", // Abre teclado numérico em dispositivos móveis
                    }}
                    margin="normal"
                    required
                    error={!isPhoneValid}
                    helperText={!isPhoneValid ? 'Número de celular inválido' : ''}
                  />}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  value={sharedState.customer.email}
                  fullWidth
                  margin="normal"
                  type="email"
                  required
                  onChange={handleEmailChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? 'Email inválido' : ''}
                />
              </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom>
              Valor Total: {formatCurrency(totalValue)}
            </Typography>
          </Paper>
        </motion.div>

        {isPhoneValid && isEmailValid && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <Paper style={{ padding: 20, marginBottom: 20, width: '95%' }}>
              <Typography variant="h6" gutterBottom>
                Forma de pagamento
              </Typography>
              <RadioGroup row value={paymentMethod} onChange={handlePaymentMethodChange}>
                {/* <FormControlLabel
                  value="creditCard"
                  control={<Radio />}
                  label={
                    <Box textAlign="center">
                      <Typography variant="body2">Cartão de Crédito</Typography>
                      <img src="/imgs/payment_creditcard.png" alt="Cartão de Crédito" style={{ height: 50 }} />
                      <Typography variant="body2">Até 12x</Typography>
                    </Box>
                  }
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '8px',
                    margin: '8px',
                    flex: 1,
                    '&.Mui-checked': {
                      borderColor: '#4CAF50'
                    }
                  }}
                /> */}
                {/* <FormControlLabel
                  value="debitCard"
                  control={<Radio />}
                  label={
                    <Box textAlign="center">
                      <Typography variant="body2">Cartão de Débito</Typography>
                      <img src="/imgs/payment_debitcard.png" alt="Cartão de Débito" style={{ height: 50 }} />
                    </Box>
                  }
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '8px',
                    margin: '8px',
                    flex: 1,
                    '&.Mui-checked': {
                      borderColor: '#4CAF50'
                    }
                  }}
                /> */}
                <FormControlLabel
                  value="pix"
                  control={<Radio />}
                  label={
                    <Box textAlign="center">
                      <Typography variant="body2">Pix</Typography>
                      <img src="/imgs/payment_pix.png" alt="Pix" style={{ height: 50 }} />
                    </Box>
                  }
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '8px',
                    margin: '8px',
                    flex: 1,
                    '&.Mui-checked': {
                      borderColor: '#4CAF50'
                    }
                  }}
                />
                {/* <FormControlLabel
                  value="boleto"
                  control={<Radio />}
                  label={
                    <Box textAlign="center">
                      <Typography variant="body2">Boleto</Typography>
                      <img src="/imgs/payment_boleto.png" alt="Boleto" style={{ height: 50 }} />
                    </Box>
                  }
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '8px',
                    margin: '8px',
                    flex: 1,
                    '&.Mui-checked': {
                      borderColor: '#4CAF50'
                    }
                  }}
                /> */}
              </RadioGroup>
              {paymentMethod === 'creditCard' && <CreditCard />}
              {paymentMethod === 'debitCard' && <DebitCard />}
              {paymentMethod === 'pix' && <Pix />}
              {paymentMethod === 'boleto' && <Boleto />}
              {paymentMethod !== '' && (
                <Typography variant="body2" sx={{ color: '#888', marginTop: 1 }}>
                  Se necessário a baixa da negativação no SPC poderá ocorrer em até 5 dias úteis após a confirmação do pagamento.
                  Caso ocorra dois pagamentos em pedidos distintos da mesma parcela, o sistema efetuará a baixa da próxima parcela do mesmo contrato.
                </Typography>
              )}
            </Paper>
          </motion.div>

        )}

        <Box mt={2} display="flex" justifyContent="center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={handleConfirmPayment}
              disabled={!isPaymentEnabled}
            >
              Pagar
            </Button>
          )}
        </Box>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Payment;
