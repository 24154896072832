import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const Pix = () => {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Pix
      </Typography>
      {/* <TextField
        label="Chave Pix"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Nome do Titular"
        fullWidth
        margin="normal"
        required
      /> */}
    </Box>
  );
};

export default Pix;
